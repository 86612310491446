.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #5c5b5b;
  height: 100%;
}
.appHeader{
  flex:1;
  text-align: center;
  width: 100%;
    color: white;
  padding: 1rem 0;
  font-size: x-large;
  background-color: #414141;
  font-weight: bolder;
}
.appHeaderSub{
  font-size: medium;
  background-color: #464949;
  padding: 2vh;
}
.paramsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #5c5b5b;
}


.inputContainer{
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  align-items: center;
  padding: 1rem;
}
.inputTitle{
  flex:1;
  font-size: large;
  color: white;
  font-weight: bolder;
}
.footer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.resultsContainer{
  display: flex;
  background-color: #5c5b5b;
  flex-direction: row;
  height: 40%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-width: 0.3rem;
  border-color: #424242;
  border-style: dashed;

}
.algCardContainer{
  padding: 0.5rem;
}
.resultsContainerContainer{
  display: flex;
  background-color: #5c5b5b;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0.3rem;
  border-color: #424242;
  border-style: dashed;
}
.inputNumber{
  flex: 1;
  background-color:white;
  border-radius: 10%;
}

.inputSelect{
  flex:1;
}
.inputOption{
  flex:1;
}
.submitContainer{
  flex:1;
  width: 30%;
  align-self: center;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
.algCard{
  display: flex;
  background-color: #414141;
  border-radius: 10%;
  display: flex;
  width: 100%;
  max-width: fit-content;
  min-width: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-width: 0.3rem;
  border-color: #292929;
  border-style: dashed;
}
.algCardContent{
  padding: 2vh;
  flex:1;
  background-color: #363636;
  border-radius: 10%;
  text-align: center;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.algCardTitle{
  white-space:nowrap;
  color: white;
  text-align: left!;
  font-weight: bold;
  font-size: large;
}
.algCardP{
  white-space:nowrap;
  color: white;
  overflow:hidden;
}
.algTypeDividerContainer{
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: center;
}
.algTypeDivider{
  flex: 1;
  padding: .5vh;
  width: 90%;
  border-radius: 25%;
  background-color: cornflowerblue;
}

.algCardRes{
  border-radius: 5%;
}

.notebookContainer{
  flex:1;
  width: 85%;
  height: 100%;
}
.notebookIframe{
  flex:1;
  padding: 1%;
  width: 100%;
  height: 90vh;
  border: none;
}